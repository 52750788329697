.download-container {
  @include vw(min-height, 1000);
  @include breakpoint(mobile) {
    @include vwMobile(min-height, 500);
  }
  h2 {
    @include vw(font-size, 35);
    line-height: 1.4;
    @include vw(margin-bottom, 20);
    @include breakpoint(mobile) {
      @include vwMobile(font-size, 25);
      @include vwMobile(margin-bottom, 30);
      @include vwMobile(margin-top, 30);
    }
  }
  .download-description {
    * {
      color: $gray-dark;
      @include vw(font-size, 20);
      @include vw(margin-bottom, 20);
      line-height: 2;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
      }
    }
    @include vw(margin-bottom, 55);
    @include breakpoint(mobile) {
      @include vwMobile(margin-bottom, 30);
    }
  }
  .download-box {
    border: 1px solid $white02;
    background-color: $blueDark;
    @include vw(margin-bottom, 40);
    .download-box-corners {
      height: 100%;
      width: 100%;
      @include buildCorners(20, 0);
      @include breakpoint(mobile) {
        @include buildCornersMobile(20, 0);
      }
      .section-padding {
        @include vwAll(padding, 7, 13, 13, 13);
      }
    }
    .download-box-padding {
      @include vw(padding, 20);
    }
    .download-box-du-image {
      width: 100%;
      @include vw(height, 217);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      @include vw(margin-bottom, 35);
      @include breakpoint(mobile) {
        @include vwMobile(height, 270);
        @include vwMobile(margin-bottom, 35);
      }
    }
    .download-box-du-title {
      * {
        @include vw(font-size, 26);
        // @include vw(margin-bottom, 20);
        line-height: normal;
        font-weight: bold;
        text-transform: uppercase;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 26);
        }
      }
      @include vw(margin-bottom, 10);
      @include breakpoint(mobile) {
        @include vwMobile(margin-bottom, 10);
      }
    }
    .download-box-du-desc {
      * {
        color: $gray-dark;
        @include vw(font-size, 20);
        line-height: 2;
        @include breakpoint(mobile) {
          @include vwMobile(font-size, 16);
        }
      }
      @include vw(margin-bottom, 30);
      @include breakpoint(mobile) {
        @include vwMobile(margin-bottom, 30);
      }
    }
  }
  .buttons {
    display: flex;
    justify-content: space-around;
  }
  .button-container {
    display: flex;
    width: auto;
    justify-content: center;
    position: relative;
    @include breakpoint(mobile) {
      @include vwMobile(margin-bottom, 10);
    }
    .theme-button {
      @include vw(min-width, 360);
      @include vw(height, 65);
      @include breakpoint(mobile) {
        @include vwMobile(min-width, 300);
        @include vwMobile(height, 65);
      }
      .theme-button-container {
        div {
          background-image: url('../../../../assets/images/icons/no-border/Icon-Download.svg');
        }
      }
    }
  }
  .download-box-myDu-notice {
    * {
      color: $white;
      @include vw(font-size, 20);
      line-height: 1.5;
      font-weight: 900;
      text-align: center;
      text-transform: uppercase;
      @include breakpoint(mobile) {
        @include vwMobile(font-size, 16);
      }
    }
    a {
      color: $yellowIg;
      text-decoration: underline;
    }
    @include vw(margin-top, 40);
    @include breakpoint(mobile) {
      @include vwMobile(margin-top, 40);
    }
  }
}
